<template>
  <div class="goods">
    <div class="image">
      <van-image :src="goods.product.thumb_image" />
    </div>
    <div class="info">
      <div class="name">{{ goods.product.name }}</div>
      <div class="size">规格：1 * {{ goods.product.size }}</div>
      <div class="amount">销售单价：￥{{ goods.price }}/{{ goods.product.least_unit }}</div>
      <div class="amount">提成单价：￥{{ goods.commission }}/{{ goods.product.least_unit }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    goods: {}
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/color";
.goods {
  display: flex;
  padding: 10px;
  background-color: #fff;
}
.image, .image .van-image {
  width: 80px;
  height: 80px;
}
.info {
  margin-left: 10px;
  width: 100%;
}
.name {
  font-size: 15px;
  word-break: break-all;
}
.size, .storage, .quantity {
  font-size: 13px;
  color: #989898;
  margin-top: 2px;
}
.amount {
  color: $amountRedColor;
  font-size: 13px;
  margin-top: 2px;
}
</style>
