<template>
  <div>
    <my-nav-bar
      title="今日特惠购"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <div class="list">
        <van-empty v-if="showEmpty" description="暂无数据" />
        <div v-for="(goods, index) in list" :key="index" class="goods-container">
          <promote-sales-today-goods :goods="goods" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getPromoteSalesProducts } from '@/api/mine'
import PromoteSalesTodayGoods from '@/components/PromoteSalesGoods/today'
export default {
  components: { myNavBar, PromoteSalesTodayGoods },
  data() {
    return {
      list: [],
      showEmpty: false
    }
  },
  created() {
    this.beginLoad()
    getPromoteSalesProducts().then(res => {
      this.endLoad()
      this.list = res.data
      this.showEmpty = this.list.length === 0
    })
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 10px;
}
.goods-container {
  margin-bottom: 10px;
}
.goods-container:last-child {
  margin-bottom: 0;
}
</style>
